<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="message_popup">
                                <div
                                    class="message_popup__content"
                                >
                                    <div class="message_popup__content-title" v-if="title">
                                        {{ title }}
                                    </div>
                                    <div class="message_popup__content-body" v-if="content">
                                        <p>
                                            {{ content }}
                                        </p>
                                    </div>
                                    <div
                                        class="message_popup__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            閉じる
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./MessagePopup.ts" />
<link type="scss" scoped src="./MessagePopup.scss" />

