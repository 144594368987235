<template>
    <div class="list_applicants_preload">
        <div class="list_applicants_preload__title" >
            <p>応募者一覧</p>
        </div>
        <div class="list_applicants_preload__filter">
            <form v-if="false">
                <div class="form-ctrl">
                    <input type="text" placeholder="ニックネームやスキル名で絞り込み" />
                </div>
                <div class="form-ctrl">
                    <select name="status" >
                        <option value="all">すべて</option>
                        <option value="selected">選定済</option>
                        <option value="unselected">募集中</option>
                    </select>
                </div>
                <div class="table-infor">
                    <div class="preloader"></div>
                </div>
            </form>
        </div>
        <div class="list_applicants_preload__table">
            <table>
                <thead>
                    <tr>
                        <th>
                            <a>応募日</a>
                        </th>
                        <th>
                            <a>応募者</a>
                        </th>
                        <th>
                            <a>評価</a>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(index) of 5" :key="index">
                    <tr class="row_field" >
                        <td colspan="4">
                            <div class="preloader"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/project-applicant-list-preload/ProjectApplicantListPreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/project-applicant-list-preload/ProjectApplicantListPreload.scss" scoped>
</style>
