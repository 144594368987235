<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="project_apply_applicant">
                                <div
                                    class="project_apply_applicant__content"
                                >
                                    <div class="project_apply_applicant__content-title">
                                        チーム最終決定
                                    </div>
                                    <div class="project_apply_applicant__content-body">
                                        <p>
                                            チーム選定を終了します。<br/>
                                            よろしければ決定ボタンを押してください。
                                        </p>
                                    </div>
                                    <div
                                        class="project_apply_applicant__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            @click="applyApplicant"
                                            type="button"
                                            class="button-red btn-md shadow"
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                        >
                                            <i
                                                v-if="state.isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            選定する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./ProjectConfirmAssignApplicants.ts" />
<link type="scss" scoped src="./ProjectConfirmAssignApplicants.scss" />

