<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="applicant_project_completed__container">
                <div class="applicant_project_completed__container-title">
                  マッチング結果
                </div>
                <p class="applicant_project_completed__container-subtitle">
                  マッチングに成功しました。<br>
                  チーム選択期間終了までもうしばらくお待ちください
                </p>
              </div>
              <button class="btn-light-gray btn-md" @click="closeSlackAlert()">閉じる</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/matching-alert/MatchingAlert.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/matching-alert/MatchingAlert.scss" />