import { __assign, __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted, computed, watch, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { orderBy, cloneDeep } from "lodash";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import ProjectApplicantListPreload from "@/presentation/components/preloader/project-applicant-list-preload/ProjectApplicantListPreload.vue";
import ProjectConfirmAssignApplicantsPopup from "@/presentation/components/modals/project-confirm-assign-applicants/ProjectConfirmAssignApplicants.vue";
import ProjectConfirmNote from "@/presentation/components/modals/project-confirm-note/ProjectConfirmNote.vue";
import TransformUtil from '@/commons/TransformUtil';
import RoutePath from '@/commons/RoutePath';
import CommonService from '@/services/CommonService';
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import MessagePopup from "@/presentation/components/modals/message-popup/MessagePopup.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import { getCurrentAccountId, getCurrentOrganizationId } from "@/commons/UserStatusUtis";
import MatchingAlert from "@/presentation/components/modals/matching-alert/MatchingAlert.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import RoundInteractor from "@/domain/usecases/RoundInteractor";
export default defineComponent({
    name: "ProjectDetailApplicantsList",
    components: {
        ProjectApplicantListPreload: ProjectApplicantListPreload,
        ProjectConfirmAssignApplicantsPopup: ProjectConfirmAssignApplicantsPopup,
        ProjectConfirmNote: ProjectConfirmNote,
        Alert: Alert,
        Textarea: Textarea,
        MessagePopup: MessagePopup,
        Preloaders: Preloaders,
        MatchingAlert: MatchingAlert
    },
    setup: function () {
        var _this = this;
        var routePath = RoutePath;
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var roundInteractor = DIContainer.instance.get(RoundInteractor);
        var route = useRoute();
        var router = useRouter();
        var store = useStore();
        var state = reactive({
            page: 1,
            limit: localStorage.getItem("projectPerPage") ? localStorage.getItem("projectPerPage") : 10,
            total_count: 0,
            projectId: "",
            request: [],
            keyword: "",
            listApplicants: [],
            displayListApplicants: [],
            totalApplicants: 0,
            fieldSortName: "",
            fieldSortType: "",
            noData: false,
            loading: false,
            filterStatus: "",
            preload: true,
            showHiddenApplicant: false,
            loadingHidden: false,
            isOwner: false,
            applicantInfo: {},
            showConfirmNote: false,
            currentApplicant: {},
            showAlertPopup: false,
            alertContent: {
                content: "メモを更新しました",
                title: ""
            },
            projectStatus: "",
            currentUserLogged: null,
            showConfirmAssignPopup: false,
            errorPopupMessage: null,
            selectedApplicants: [],
            systemSettings: window['laravelEnv'].systemSettings,
            showMatchingResultPopupAlert: false,
            MatchingResult: false,
            MatchingInOperation: false,
            isDisabled: false,
            organizationMultipleMatchingStatus: false,
            roundAdditionalMatchingStatues: false,
            applicantsCount: 0
        });
        var filteredSelectedProjectRequest = function (applicant) {
            var filteredRequests = state.request
                .filter(function (item) {
                var selectedProjectRequest = JSON.parse(item.selected_project_request);
                if (selectedProjectRequest && applicant.team_id) {
                    return (item.team_id === applicant.team_id &&
                        selectedProjectRequest.some(function (req) { return req.id === state.projectId; }));
                }
            })
                .map(function (item) {
                var selectedProjectRequest = JSON.parse(item.selected_project_request);
                if (selectedProjectRequest && applicant.team_id) {
                    var filteredSelectedProjectRequest_1 = selectedProjectRequest.filter(function (req) { return req.id === state.projectId; });
                    return __assign(__assign({}, item), { selected_project_request: filteredSelectedProjectRequest_1.map(function (req) { return req.reason; }) });
                }
            });
            return filteredRequests;
        };
        var filteredListApplicants = computed(function () {
            if (!state.keyword) {
                return state.displayListApplicants;
            }
            var keyword = state.keyword;
            return state.displayListApplicants.filter(function (applicant) {
                var teamName = applicant.team_name;
                var noteByOrganization = applicant.note_by_organization;
                var skillsName = applicant.skillsName;
                var prefectures = applicant.prefectures.map(function (prefecture) { return prefecture.toLowerCase(); });
                return teamName.includes(keyword) || prefectures.some(function (prefecture) { return prefecture.includes(keyword); }) || noteByOrganization.includes(keyword) || skillsName.includes(keyword);
            });
        });
        var projectInfo = computed(function () { var _a; return (_a = store === null || store === void 0 ? void 0 : store.state) === null || _a === void 0 ? void 0 : _a.projectInfo; });
        var projectOwnerAccountId = computed(function () { return Number(projectInfo.value.project_owner_organization_id); });
        var applicantsAssigned = computed(function () {
            return (state.applicantsCount === 0 ||
                (state.roundAdditionalMatchingStatues && state.organizationMultipleMatchingStatus && state.applicantsCount === 1));
        });
        watch(function () { return projectInfo.value; }, function (value) {
            if (value.progress_status === 'temp_saved' ||
                value.progress_status === 'stop' ||
                value.progress_status === 'unapproved' ||
                value.progress_status === 'rejected' ||
                value.project_owner_organization_id !== getCurrentOrganizationId()) {
                router.push(RoutePath.PAGE_404);
            }
        });
        watch(projectInfo, function (newVal) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newVal && Object.keys(newVal).length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getMultipleMatchingStatus()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }, { immediate: true });
        function getMultipleMatchingStatus() {
            var parsedId = projectOwnerAccountId.value;
            return organizationInteractor.getMultipleMatchingStatus(parsedId).then(function (response) {
                state.organizationMultipleMatchingStatus = response.is_multiple_matching;
            });
        }
        function getAdditionalMatching() {
            return roundInteractor.getAdditionalMatching().then(function (response) {
                state.roundAdditionalMatchingStatues = response.is_additional_matching;
            });
        }
        function getApplicantsCount() {
            return organizationProjectInteractor.getApplicantsCount(Number(route.params.id)).then(function (response) {
                state.applicantsCount = response.count;
            });
        }
        var isDisabled = computed(function () { return store.state.isDisabled; });
        function getListApplicants(action) {
            if (action === void 0) { action = ""; }
            var param = null;
            var filterStatus = null;
            if (state.filterStatus) {
                filterStatus = state.filterStatus;
            }
            if (store.state.projectDetailMatchingFilter) {
                param = store.state.projectDetailMatchingFilter;
            }
            organizationProjectInteractor.getOrganizationProjectAssignable(Number(route.params.id), param).then(function (result) {
                state.loadingHidden = false;
                state.loading = false;
                state.preload = false;
                result.data = result.data.filter(function (applicant) { return !(applicant.status === "SELECTED" || applicant.status === "VOTED"); });
                if (result.data.length == 0) {
                    if (action == "new" || action == "mount") {
                        state.noData = action == "mount" ? true : false;
                        state.total_count = result.total_count;
                        state.listApplicants = [];
                        state.displayListApplicants = [];
                    }
                    else {
                        state.noData = false;
                    }
                    return;
                }
                else {
                    state.noData = false;
                    var defaultSelectedApplicants = [];
                    result.data.forEach(function (applicant) {
                        applicant.date = TransformUtil.convertDatetime(applicant.date);
                        applicant.user_relations_description = TransformUtil.convertShortText(applicant.user_relations_description, 200);
                        applicant.user_rating_ave = TransformUtil.pointRound(applicant.user_rating_ave);
                        applicant.estimate_price = TransformUtil.formatNumber(applicant.estimate_price);
                        applicant.user_rating_count = TransformUtil.formatNumber(applicant.user_rating_count);
                        var skillsNameCount = 0;
                        var skillsItNameCount = 0;
                        applicant.skillsName = "";
                        applicant.skillsItName = "";
                        applicant.skills.map(function (skill) {
                            if (skill.skill_id >= 1056 && skill.skill_id <= 1062 || skill.skill_id == 1079) {
                                if (skillsItNameCount === 0) {
                                    applicant.skillsItName += skill.skill_name;
                                }
                                else {
                                    applicant.skillsItName += ", " + skill.skill_name;
                                }
                                skillsItNameCount++;
                            }
                            else {
                                if (skillsNameCount === 0) {
                                    applicant.skillsName += skill.skill_name;
                                }
                                else {
                                    applicant.skillsName += ", " + skill.skill_name;
                                }
                                skillsNameCount++;
                            }
                        });
                        applicant.categoriesName = "";
                        applicant.residencePrefecturesName = "";
                        applicant.prefecturesName = "";
                        applicant.availableDatetimesName = "";
                        applicant.categories.map(function (category, index) {
                            if (index > 0)
                                applicant.categoriesName += ", ";
                            applicant.categoriesName += "第" + (index + 1) + "希望：" + category;
                        });
                        applicant.available_datetimes.map(function (available_datetime, index) {
                            if (index > 0)
                                applicant.availableDatetimesName += ", ";
                            applicant.availableDatetimesName += available_datetime;
                        });
                        applicant.prefectures.map(function (prefecture, index) {
                            if (index > 0)
                                applicant.prefecturesName += ", ";
                            applicant.prefecturesName += prefecture;
                        });
                        applicant.residence_prefectures.map(function (residence_prefecture, index) {
                            if (index > 0)
                                applicant.residencePrefecturesName += ", ";
                            applicant.residencePrefecturesName += residence_prefecture;
                        });
                    });
                    state.selectedApplicants = defaultSelectedApplicants;
                }
                state.listApplicants = result.data;
                state.total_count = result.total_count;
                state.request = result.request;
                state.projectId = result.projectId;
                resetDisplayListApplicants();
            }).catch(function (error) {
                state.loadingHidden = false;
                state.loading = false;
                state.preload = false;
            });
        }
        function onChangeKeyword(event) {
            state.page = 1;
            state.keyword = event.target.value;
        }
        function sortTable(field) {
            if (field === state.fieldSortName) {
                state.fieldSortType = state.fieldSortType === "desc" ? "asc" : "desc";
            }
            else {
                state.fieldSortType = "asc";
            }
            state.fieldSortName = field;
            resetDisplayListApplicants();
        }
        function resetDisplayListApplicants() {
            var cloneList = cloneDeep(state.listApplicants);
            cloneList = cloneList.filter(function (row) { return (state.showHiddenApplicant ? true : row.is_hidden_by_organization === 0); });
            state.displayListApplicants = orderBy(cloneList, [state.fieldSortName], [state.fieldSortType]);
        }
        function selectApplicant(target) {
            var clone = cloneDeep(state.selectedApplicants);
            if (detectApplicantSelected(target)) {
                clone = clone.filter(function (row) { return row.id !== target.applicant_id; });
            }
            else if (state.selectedApplicants.length < state.systemSettings.project_teams_upper_limit) {
                clone.push({
                    id: target.applicant_id,
                    matched: target.matched
                });
            }
            state.selectedApplicants = clone;
        }
        function detectApplicantSelected(target) {
            return !!state.selectedApplicants.find(function (row) { return row.id === target.applicant_id; });
        }
        function handleNoteChange(e, applicantId) {
            state.listApplicants.forEach(function (row, i) {
                if (row.applicant_id == applicantId) {
                    state.listApplicants[i].note_by_organization = e.target.value;
                }
            });
        }
        function confirmNote(applicant) {
            state.currentApplicant = applicant;
            state.showConfirmNote = true;
        }
        function saveNote() {
            var applicantId = state.currentApplicant.applicant_id;
            state.listApplicants.forEach(function (applicant, index) {
                if (state.listApplicants[index].applicant_id == applicantId) {
                    state.listApplicants[index].updating = true;
                    var param = {
                        note_by_organization: state.listApplicants[index].note_by_organization
                    };
                    organizationProjectInteractor.updateOrganizationProjectApplicant(state.listApplicants[index].applicant_id, param).then(function () {
                        state.listApplicants[index].updating = false;
                        state.alertContent.content = "メモを更新しました";
                        state.showAlertPopup = true;
                        state.showConfirmNote = false;
                    }).catch(function () {
                        state.listApplicants[index].updating = false;
                        state.showConfirmNote = false;
                    });
                }
            });
        }
        function toggleApplicant(applicant) {
            applicant.toggling = true;
            var param = {
                is_hidden_by_organization: applicant.is_hidden_by_organization ? 0 : 1
            };
            organizationProjectInteractor.updateOrganizationProjectApplicant(applicant.applicant_id, param).then(function () {
                applicant.toggling = false;
                applicant.is_hidden_by_organization = param.is_hidden_by_organization;
                var list = cloneDeep(state.listApplicants);
                var i = list.findIndex(function (row) { return row.applicant_id === applicant.applicant_id; });
                if (i >= 0) {
                    list[i].is_hidden_by_organization = param.is_hidden_by_organization;
                }
                state.listApplicants = list;
                // 非表示にした企業が選択中の場合は選択を外す
                var indexOfSelectedApplicant = state.selectedApplicants.findIndex(function (selectedApplicant) {
                    return selectedApplicant.id === applicant.applicant_id;
                });
                if (applicant.is_hidden_by_organization && indexOfSelectedApplicant >= 0) {
                    state.selectedApplicants.splice(indexOfSelectedApplicant, 1);
                }
                resetDisplayListApplicants();
            }).catch(function () {
                applicant.toggling = false;
                applicant.is_hidden_by_organization = !param.is_hidden_by_organization;
            });
        }
        function toggleHiddenApplicant() {
            state.showHiddenApplicant = !state.showHiddenApplicant;
            resetDisplayListApplicants();
        }
        function openAssignConfirm() {
            store.dispatch('checkActiveTeamSelect').then(function () {
                if (!isDisabled.value) {
                    if (state.selectedApplicants.length === 0)
                        return;
                    state.showConfirmAssignPopup = true;
                }
            });
        }
        function applyApplicants() {
            state.MatchingInOperation = true;
            state.showMatchingResultPopupAlert = true;
            store.commit('setDefaultErrorAlertStatus', false);
            return organizationProjectInteractor.updateOrganizationProjectAssign(Number(projectInfo.value.project_id), {
                applicants: cloneDeep(state.selectedApplicants)
            })
                .then(function (result) {
                state.MatchingInOperation = false;
                state.showConfirmAssignPopup = false;
                state.MatchingResult = true;
                store.commit('setDefaultErrorAlertStatus', true);
                // emit('reloadproject');
            }).catch(function (error) {
                // マッチングエラー
                state.showMatchingResultPopupAlert = false;
                state.MatchingInOperation = false;
                state.showConfirmAssignPopup = false;
                state.errorPopupMessage = "登録に失敗しました。";
                store.commit('setDefaultErrorAlertStatus', true);
            });
        }
        function closeSlackAlert() {
            state.showMatchingResultPopupAlert = false;
            state.MatchingResult = false;
            router.go({ path: route.path, force: true });
        }
        var unsubscribe = null;
        onMounted(function () {
            store.commit('setProjectDetailMatchingFilter', null);
            unsubscribe = store.subscribe(function (mutation) {
                if (mutation.type === 'setProjectDetailMatchingFilter') {
                    getListApplicants("mount");
                }
            });
            if (store.state.projectInfo && Object.keys(store.state.projectInfo).length > 0 && store.state.projectInfo.constructor === Object) {
                state.projectStatus = store.state.projectInfo.progress_status;
                var user = CommonService.getCookie("user");
                if (user) {
                    state.isOwner = store.state.projectInfo.project_owner_organization_id === getCurrentOrganizationId();
                    state.showHiddenApplicant = state.isOwner ? false : true;
                    state.currentUserLogged = getCurrentAccountId();
                    getListApplicants("mount");
                }
            }
            else {
                unsubscribe = store.subscribe(function (mutation, stateVuex) {
                    if (mutation.type === "setProjectInfo") {
                        state.projectStatus = stateVuex.projectInfo.progress_status;
                        var user = CommonService.getCookie("user");
                        if (user) {
                            state.isOwner = store.state.projectInfo.project_owner_organization_id === getCurrentOrganizationId();
                            state.showHiddenApplicant = state.isOwner ? false : true;
                            state.currentUserLogged = getCurrentAccountId();
                            getListApplicants("mount");
                        }
                    }
                });
            }
            store.dispatch('checkActiveTeamSelect');
            getAdditionalMatching();
            getApplicantsCount();
        });
        onUnmounted(function () {
            if (unsubscribe) {
                unsubscribe();
            }
        });
        function closeConfirmNote() {
            state.currentApplicant = {};
            state.showConfirmNote = false;
        }
        return {
            state: state,
            onChangeKeyword: onChangeKeyword,
            sortTable: sortTable,
            saveNote: saveNote,
            toggleApplicant: toggleApplicant,
            toggleHiddenApplicant: toggleHiddenApplicant,
            openAssignConfirm: openAssignConfirm,
            applyApplicants: applyApplicants,
            routePath: routePath,
            closeConfirmNote: closeConfirmNote,
            confirmNote: confirmNote,
            selectApplicant: selectApplicant,
            detectApplicantSelected: detectApplicantSelected,
            projectInfo: projectInfo,
            applicantsAssigned: applicantsAssigned,
            handleNoteChange: handleNoteChange,
            closeSlackAlert: closeSlackAlert,
            filteredListApplicants: filteredListApplicants,
            isDisabled: isDisabled,
            filteredSelectedProjectRequest: filteredSelectedProjectRequest
        };
    }
});
