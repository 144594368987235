<template>
<div class="project_detail__container__body">
  <!-- <ProjectApplicantListPreload v-if="state.preload" /> -->
    <div class="list_applicants">
      <div class="list_applicants__title">
        <p>希望したチーム一覧</p>
      </div>
      <Preloaders v-if="state.preload" />
      <div v-else>
        <div class="list_applicants__description" v-if="applicantsAssigned">
          <p>
            最大{{ state.systemSettings.project_teams_upper_limit }}チーム以内で、ご選択ください。<br/>
            ※一覧には、他のプロジェクトにアサインされていないチームのみ表示しております。
          </p>
          <button
            @click="openAssignConfirm"
            :disabled="isDisabled"
            :class="{
              'btn-md button-red shadow': !isDisabled,
              'btn-md btn-light-gray shadow disabled': isDisabled,
              'disabled': state.selectedApplicants.length === 0
            }"
          >
            チームを決定する
          </button>
        </div>
        <div class="list_applicants__description" v-else>
          <p></p>
          <button
            class="btn-md btn-light-gray shadow disabled"
          >
            チーム決定済み
          </button>
        </div>
        <div class="form-ctrl">
          <div class="list_applicants__searchtitle">
            <p>フリーワード検索</p>
          </div>
          <input
          type="text"
          v-model="state.keyword"
          placeholder="フリーワードを入力"
          @input="onChangeKeyword"
          />
          <div class="list_applicants__description" v-if="applicantsAssigned">
            <p>
              ※チーム名・スキル・対応可能エリア・メモ欄からご検索ください。
            </p>
          </div>
        </div>
        <div class="list_applicants__table__info">
            {{ filteredListApplicants.length }}件表示 /
            {{ state.total_count }}件中
        </div>
        <div class="list_applicants__table">
          <table>
            <thead>
              <tr>
                <th
                  class="is-checkbox"
                  :class="{'width-300': !state.isOwner}"
                  v-if="applicantsAssigned"
                >
                  <b>
                    選択 {{ state.selectedApplicants.length }}/{{ state.systemSettings.project_teams_upper_limit }}
                  </b>
                </th>
                <th
                  class="is-applicant"
                  :class="{'width-450': !state.isOwner}"
                >
                  <a
                    :class="{
                      active:
                        state.fieldSortName == 'team_name' &&
                        state.fieldSortType == 'asc',
                    }"
                    @click="sortTable('team_name')"
                  >
                    応募チーム
                  </a>
                </th>
                <th class="is-note">
                  <a>メモ</a>
                  <p class="Required">※青い部分をクリックして<br />メモを保存してください</p>
                </th>
                <th class="is-visibility">
                  <a @click="toggleHiddenApplicant()">
                      非表示
                      <i v-if="!state.loadingHidden && state.showHiddenApplicant" class="fas fa-eye"></i>
                      <i v-if="!state.loadingHidden && !state.showHiddenApplicant" class="fas fa-eye-slash"></i>
                      <i v-if="state.loadingHidden" class="fas fa-circle-notch fa-spin"></i>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody v-if="!state.displayListApplicants || state.displayListApplicants.length == 0 || filteredListApplicants.length === 0">
                <tr>
                    <td colspan="6" class="list_applicants__table__no_data">
                        {{state.noData ? "チームがありません" : "検索結果のデータがありません"}}
                    </td>
                </tr>
            </tbody>
            <template v-if="state.displayListApplicants.length">
              <tbody v-for="(applicant, i) of filteredListApplicants" :key="applicant.project_id">
                <tr class="row_field">
                  <td
                    v-if="applicantsAssigned"
                    class="is-checkbox"
                  >
                    <input
                      type="checkbox"
                      :id="`select_${i}`"
                      :checked="detectApplicantSelected(applicant)"
                      @change="selectApplicant(applicant)"
                      :disabled="!detectApplicantSelected(applicant) && state.selectedApplicants.length >= state.systemSettings.project_teams_upper_limit"
                    />
                    <label
                      :for="`select_${i}`"
                    />
                  </td>
                  <td class="is-applicant">
                    <div class="applicant">
                      <router-link
                        :to="{ path: `/teams/${applicant.team_id}` }"
                      >
                        {{applicant.team_name}}
                      </router-link>
                    </div>
                  </td>
                  <td class="is-note">
                    <div class="note">
                      <form class="note" @submit.prevent="confirmNote(applicant)">
                        <Textarea
                          class="applicant-note"
                          maxlength="25"
                          v-model="applicant.note_by_organization"
                          @input="e => handleNoteChange(e, applicant.applicant_id)"
                          placeholder="メモ：チーム側には表示されません。備忘など、ご自由にお使いください"
                        />
                        <button type="submit" class="shadow">
                          <i v-if="applicant.updating" class="fas fa-circle-notch fa-spin"></i>
                          <span v-else>保存</span>
                        </button>
                      </form>
                    </div>
                  </td>
                  <td class="is-visibility">
                    <a v-if="applicant.toggling"><i class="fas fa-circle-notch fa-spin"></i></a>
                    <a class="close" @click="toggleApplicant(applicant)" v-else-if="applicant.is_hidden_by_organization == false"></a>
                    <a v-else @click="toggleApplicant(applicant)">再表示</a>
                  </td>
                </tr>
                <tr
                  class="row_content"
                >
                  <td colspan="6">
                    <div>
                      <h6>チームの業種</h6>
                      <p v-if="applicant.skillsItName">{{ applicant.skillsItName }}</p>
                      <h6>チームのスキル</h6>
                      <p v-if="applicant.skillsName">{{ applicant.skillsName }}</p>
                      <p class="row_content__note" ><pre>{{ applicant.user_relations_description }}</pre></p>
                      <h6>対応可能時間帯</h6>
                      <p v-if="applicant.availableDatetimesName">{{ applicant.availableDatetimesName }}</p>
                      <h6>希望理由</h6>
                      <template v-for="item in filteredSelectedProjectRequest(applicant)">
                        <p class="project-request">{{item.selected_project_request[0]}}</p>
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ProjectConfirmNote v-if="state.showConfirmNote" @close="closeConfirmNote" @accept="saveNote()" />
  <Alert
      v-if="state.showAlertPopup == true"
      :content="state.alertContent.content"
      @close="state.showAlertPopup = false"
    />
  <ProjectConfirmAssignApplicantsPopup
    v-if="state.showConfirmAssignPopup"
    :source="displayListApplicants"
    :selectedApplicants="selectedApplicants"
    @apply="applyApplicants"
    @close="state.showConfirmAssignPopup = false;"
  />
  <MatchingAlert
    v-if="state.showMatchingResultPopupAlert"
    :MatchingInOperation="state.MatchingInOperation"
    :MatchingResult="state.MatchingResult"
    @close="closeSlackAlert"
  />
  <MessagePopup
    v-if="state.errorPopupMessage !== null"
    title="エラーがあります"
    :content="state.errorPopupMessage"
    @close="state.errorPopupMessage = null"
  />
</template>

<script lang="ts" src="./ProjectDetailMatches.ts" scoped>
</script>


<style lang="scss" src="../ProjectDetail.scss" scoped>
</style>
<style lang="scss" src="./ProjectDetailMatches.scss" scoped>
</style>
