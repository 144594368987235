import { defineComponent, reactive } from "vue";
export default defineComponent({
    name: "ProjectConfirmAssignApplicants",
    props: [
        "selectedApplicants",
        "source",
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            isSubmitting: false,
        });
        function applyApplicant() {
            state.isSubmitting = true;
            emit('apply');
        }
        return {
            state: state,
            props: props,
            applyApplicant: applyApplicant
        };
    }
});
